import React, { useState, useMemo } from 'react';
import { Image, Box } from 'grommet';
import { Image as ImageIcon } from 'grommet-icons';
import styles from './style.module.css';

const AssetImage = ({ url, size, ...rest }) => {
  const img = useMemo(() => {
    const img = new window.Image();
    img.src = url;
    return img;
  }, [url]);
  const [hasLoaded, setHasLoaded] = useState(img.complete);
  img.onload = () => setHasLoaded(true);

  return (
    <Box justify="center" align="center">
      {hasLoaded ? (
        <Image className={styles.assetImage} src={url} {...rest} />
      ) : (
        <ImageIcon color="brand" size={size} />
      )}
    </Box>
  );
};

AssetImage.defaultProps = {
  size: 'xlarge',
  fit: 'contain',
};

export default AssetImage;
