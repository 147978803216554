import create from 'zustand';
import sillyname from 'sillyname';
import fb, { onAuthChanged } from '../firebase';

const userStore = (set, get) => {
  const addUsername = (user, username) =>
    user.updateProfile({ displayName: username }).then(() =>
      fb
        .database()
        .ref(`users/${user.uid}`)
        .set(user.displayName)
    );

  onAuthChanged(user => {
    const username = user.displayName || sillyname().replace(/\s+/g, '-');
    addUsername(user, username);
    set({ user });
  });

  return {
    user: null,
    signIn: () => fb.auth().signInAnonymously(),
    setName: async displayName => {
      const { user } = get();
      await addUsername(user, displayName);
    },
  };
};

export const [useUserStore, userAPI] = create(userStore);
