import create from 'zustand';
import fb, { onAuthChanged } from '../firebase';
import { userAPI } from './user';

const gameStore = (set, get) => {
  onAuthChanged(({ uid }) => {
    // Initiate code editor with already synced code
    fb.database()
      .ref('entries')
      .child(uid)
      .once('value')
      .then(snapshot => {
        if (snapshot.exists()) {
          const { html, css, javascript } = snapshot.val();
          set({ html, css, javascript });
        } else {
          set({ html: '', css: '', javascript: '' });
        }
        set({ hasSynced: true });
      });
  });

  return {
    html: '',
    css: '',
    javascript: '',
    hasSynced: false,
    hasSubmitted: false,
    combo: 0,
    time: 0,
    setCode: (type, code) => set({ [type]: code }),
    setCombo: combo => set({ combo }),
    setTime: time => set({ time }),
    submitCode: () => {
      const {
        user: { uid },
      } = userAPI.getState();
      const { html, css, javascript } = get();

      fb.database()
        .ref('entries')
        .child(uid)
        .update({ html, css, javascript })
        .then(() => set({ hasSubmitted: true }));
    },
    syncCode: type => {
      const {
        user: { uid },
      } = userAPI.getState();
      const code = get()[type];

      fb.database()
        .ref('entries')
        .child(uid)
        .update({ [type]: code });
    },
  };
};

export const [useGameStore, gameAPI] = create(gameStore);
