import React from 'react';
import { Heading, Text, Image, Paragraph } from 'grommet';
import StyledView from './StyledView';
import buddha from '../assets/buddha.svg';

export default ({ error }) => (
  <StyledView gap="medium">
    <Heading textAlign="center" margin="none">
      Something went wrong
    </Heading>
    <Image style={{ width: 300 }} src={buddha} />
    <Text>Stay calm and reach out for help.</Text>
    <Paragraph>{error.toString()}</Paragraph>
  </StyledView>
);
