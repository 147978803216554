import React, { useMemo, useCallback } from 'react';
import { Box, Text, Carousel, Tabs, Tab } from 'grommet';
import { useEntriesStore } from '../stores/entries';
import { useSessionStore } from '../stores/session';
import { useUserStore } from '../stores/user';
import StyledView from './StyledView';
import style from './style.module.css';

const Entries = ({ location }) => {
  const entries = useEntriesStore();
  const users = useSessionStore(state => state.users);
  const user = useUserStore(state => state.user);

  const codes = useMemo(
    () =>
      Object.entries(entries).map(([uid, { html, css, javascript }]) => {
        const username = users[uid];
        const code = `
          <html>
            <head>
              <style>${css}</style>
              <title>${username}</title>
            </head>
            <body>
              ${html}
              <script>
                ${javascript}
              </script>
            </body>
          </html>
        `;
        // https://dev.to/pulljosh/how-to-load-html-css-and-js-code-into-an-iframe-2blc
        const blob = new Blob(['\ufeff', code], { type: 'text/html' });
        return [uid, URL.createObjectURL(blob)];
      }),
    [entries, users]
  );

  const getContent = useCallback((uid, url) => {
    return (
      <Box
        fill
        key={uid}
        justify="center"
        align="center"
        pad={{ bottom: 'small' }}
        gap="small"
      >
        <iframe
          title={uid}
          className={style.entryFrame}
          src={url}
          sandbox="allow-scripts"
        />
      </Box>
    );
  }, []);

  return (
    <StyledView>
      {location.hash.includes('tab') ? (
        <Tabs flex fill>
          {codes.map(([uid, url]) => {
            const username = users[uid] ?? "undefined (sorry)";
            return <Tab key={uid} title={username}>{getContent(uid, url)}</Tab>;
          })}
        </Tabs>
      ) : (
        <Carousel
          fill
          controls="selectors"
          play={5000}
          className={style.carousel}
        >
          {codes.map(([uid, url]) => {
            const username = users[uid];
            return (
              <>
                {getContent(uid, url)}
                <Text fill="horizontal">
                  {username} - {uid}
                </Text>
              </>
            );
          })}
        </Carousel>
      )}
    </StyledView>
  );
};

export default Entries;
