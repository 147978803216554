import React, { useEffect, useRef } from 'react';
import AceEditor from 'react-ace';
import POWERMODE from 'html-activate-power-mode';
import 'ace-builds/src-min-noconflict/mode-html';
import 'ace-builds/src-min-noconflict/mode-css';
import 'ace-builds/src-min-noconflict/mode-javascript';
import 'ace-builds/src-min-noconflict/theme-solarized_dark';
import 'ace-builds/src-min-noconflict/theme-solarized_light';
import style from './style.module.css';
import { useGameStore } from '../stores/game';
import { useSettingsStore } from '../stores/settings';
import { useDebounce } from '../hooks';
import { useSessionStore } from '../stores/session';

POWERMODE.colorful = true;
POWERMODE.shake = true;
POWERMODE.shakeSize = 1;

const themes = { light: 'solarized_light', dark: 'solarized_dark' };

const CodeEditor = ({ mode, ...rest }) => {
  const combo = useGameStore(state => state.combo);
  const setCombo = useGameStore(state => state.setCombo);
  const fontSize = useSettingsStore(state => state.fontSize);
  const theme = useSettingsStore(state => state.theme);
  const layout = useSettingsStore(state => state.layout);
  const code = useGameStore(state => state[mode]);
  const setCode = useGameStore(state => state.setCode);
  const syncCode = useGameStore(state => state.syncCode);
  const hasSynced = useGameStore(state => state.hasSynced);
  const hasStarted = useSessionStore(state => state.hasStarted);

  const ref = useRef();
  // Editor needs to be resized to recalculate proper dimensions
  useEffect(() => {
    ref.current.editor.resize();
  }, [layout]);

  // Sync code with a debounce to avoid bombing API
  const debouncedCode = useDebounce(code, 2000);
  useEffect(() => {
    hasSynced && syncCode(mode);
  }, [hasSynced, debouncedCode, mode, syncCode]);

  return (
    <AceEditor
      ref={ref}
      className={style[mode]}
      mode={mode}
      readOnly={!hasStarted || !hasSynced}
      fontSize={fontSize}
      placeholder={mode}
      theme={themes[theme]}
      value={code}
      onChange={code => {
        setCombo(combo + 1);
        setCode(mode, code);
      }}
      onInput={() => {
        combo >= 200 && POWERMODE();
      }}
      showPrintMargin={false}
      setOptions={{ useWorker: false }}
      wrapEnabled
      width="100%"
      height="100%"
      {...rest}
    />
  );
};

export default CodeEditor;
