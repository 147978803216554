import React from 'react';
import { Heading, Text, Image } from 'grommet';
import stop from '../assets/stop.svg';
import StyledView from './StyledView';

export default () => (
  <StyledView>
    <Heading textAlign="center" margin="none">
      404.
    </Heading>
    <Image style={{ width: 300 }} src={stop} />
    <Text>You shouldn't be here.</Text>
  </StyledView>
);
