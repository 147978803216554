import create from 'zustand';
import fb, { onAuthChanged } from '../firebase';

const assetsStore = set => {
  onAuthChanged(() => {
    fb.database()
      .ref('session/reference')
      .on('value', snapshot => {
        if (snapshot.exists()) {
          const reference = snapshot.val();
          set({ reference });
        }
      });

    fb.database()
      .ref('session/assets')
      .on('value', snapshot => {
        if (snapshot.exists()) {
          const assets = snapshot.val();
          set({ assets: Object.values(assets) });
        }
      });
  });

  return { reference: null, assets: [] };
};

export const [useAssetsStore] = create(assetsStore);
