import React from 'react';
import { RangeInput, Box, Text, RadioButtonGroup } from 'grommet';
import { Splits } from 'grommet-icons';
import { useSettingsStore } from '../stores/settings';

const Settings = props => {
  const {
    fontSize,
    setFontSize,
    theme,
    setTheme,
    layout,
    setLayout,
  } = useSettingsStore();

  return (
    <Box gap="large" {...props}>
      <Box gap="small">
        <Text>Font size: {fontSize}</Text>
        <RangeInput
          min={8}
          max={22}
          step={1}
          value={fontSize}
          onChange={({ target: { value } }) => setFontSize(Number(value))}
        />
      </Box>
      <Box gap="small">
        <Text>Layout:</Text>
        <RadioButtonGroup
          name="layout"
          options={[
            { label: <Splits />, value: 'row' },
            {
              label: <Splits style={{ transform: 'rotate(90deg)' }} />,
              value: 'column',
            },
          ]}
          value={layout}
          onChange={({ target: { value } }) => setLayout(value)}
        />
      </Box>
      <Box gap="small">
        <Text>Theme:</Text>
        <RadioButtonGroup
          name="theme"
          options={[
            { label: 'Light', value: 'light' },
            { label: 'Dark', value: 'dark' },
          ]}
          value={theme}
          onChange={({ target: { value } }) => setTheme(value)}
        />
      </Box>
    </Box>
  );
};

export default Settings;
