import React, { useState, useCallback, Fragment } from 'react';
import { Box, Button, Paragraph, Grid } from 'grommet';
import {
  Send,
  Attachment,
  Image as ImageIcon,
  CircleQuestion,
  Copy,
} from 'grommet-icons';
import Modal from './Modal';
import AssetImage from './AssetImage';
import { useSessionStore } from '../stores/session';
import { useAssetsStore } from '../stores/assets';
import { useGameStore } from '../stores/game';
import rules from '../rules';

const Footer = props => {
  const hasStarted = useSessionStore(state => state.hasStarted);
  const [showRules, setShowRules] = useState(false);
  const [showReference, setShowReference] = useState(false);
  const [showResources, setShowResources] = useState(false);
  const [showSubmit, setShowSubmit] = useState(false);
  const reference = useAssetsStore(state => state.reference);
  const assets = useAssetsStore(state => state.assets);
  const submitCode = useGameStore(state => state.submitCode);

  const onCopyURL = useCallback(url => navigator.clipboard.writeText(url), []);

  return (
    <>
      <Box
        as="footer"
        {...props}
        direction="row"
        justify="start"
        align="center"
        gap="medium"
        pad="medium"
      >
        <Button
          label="Rules"
          icon={<CircleQuestion />}
          onClick={() => setShowRules(true)}
        />
        <Button
          label="Resources"
          icon={<Attachment />}
          disabled={!hasStarted}
          onClick={() => setShowResources(true)}
        />
        <Button
          label="Reference"
          icon={<ImageIcon />}
          disabled={!hasStarted}
          onClick={() => setShowReference(true)}
        />
        <Button
          primary
          label="Submit"
          disabled={!hasStarted}
          icon={<Send />}
          onClick={() => setShowSubmit(true)}
          margin={{ left: 'auto' }}
        />
      </Box>
      <Modal
        title="Rules"
        isOpen={showRules}
        onClose={() => setShowRules(false)}
      >
        <ul>
          {Object.entries(rules).map(([emoji, rule]) => (
            <li style={{ listStyle: 'none' }} key={rule}>
              {emoji} - {rule}
            </li>
          ))}
        </ul>
      </Modal>
      <Modal
        title="Reference"
        full
        margin="large"
        isOpen={showReference}
        onClose={() => setShowReference(false)}
      >
        <AssetImage url={reference} />
      </Modal>
      <Modal
        title="Resources"
        isOpen={showResources}
        onClose={() => setShowResources(false)}
        gap="large"
        pad="large"
      >
        <Grid
          align="center"
          columns={['small', 'medium']}
          rows="xsmall"
          gap={{ row: 'small', column: 'large' }}
        >
          {assets.map(url => (
            <Fragment key={url}>
              <AssetImage url={url} size="small" />
              <Button
                label="Copy URL"
                icon={<Copy />}
                onClick={() => onCopyURL(url)}
              />
            </Fragment>
          ))}
        </Grid>
      </Modal>
      <Modal
        title="Submit"
        isOpen={showSubmit}
        onClose={() => setShowSubmit(false)}
      >
        <Paragraph margin="none">
          Do you want to submit your entry? This will end your game session.
        </Paragraph>
        <Button
          label="Submit"
          icon={<Send />}
          color="accent-1"
          onClick={submitCode}
        />
      </Modal>
    </>
  );
};

export default Footer;
