import React from 'react';
import { Box, Image } from 'grommet';
import blackoutLogo from '../assets/blackout.svg';
import theme from '../theme';

const {
  global: { colors },
} = theme;

const Loading = ({ condition, children }) =>
  condition ? (
    children
  ) : (
    <Box
      background="brand"
      fill
      justify="center"
      align="center"
      pad="large"
      style={{
        background: `radial-gradient(circle, ${colors.brand}, ${colors.blackout})`,
      }}
    >
      <Box width="large" justify="center" align="center">
        <Image
          fit="contain"
          style={{ maxWidth: '100%', width: '100%' }}
          src={blackoutLogo}
        />
      </Box>
    </Box>
  );

Loading.defaultProps = {
  condition: false,
};

export default Loading;
