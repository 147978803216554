import React from 'react';
import { Layer, Box, Heading, Button } from 'grommet';
import { Close } from 'grommet-icons';

const Modal = ({ isOpen, onClose, title, gap, pad, children, ...rest }) => {
  return (
    isOpen && (
      <Layer
        modal
        position="center"
        onClickOutside={onClose}
        onEsc={onClose}
        {...rest}
      >
        <Box pad={pad} gap={gap} height="100%" width="100%" justify="between">
          <Heading level={3} margin="none">
            {title}
          </Heading>
          <Box gap="medium" direction="column" overflow="auto">
            {children}
          </Box>
          <Button label="Close" icon={<Close />} onClick={onClose} primary />
        </Box>
      </Layer>
    )
  );
};

Modal.defaultProps = {
  title: 'Title',
  isOpen: false,
  onClose: () => {},
  gap: 'medium',
  pad: 'large',
};

export default Modal;
