import React, { useEffect } from 'react';
import { Text, Meter, Box, Stack } from 'grommet';
import { useInterval } from '../hooks';
import { useGameStore } from '../stores/game';

const ComboCounter = () => {
  const combo = useGameStore(state => state.combo);
  const setCombo = useGameStore(state => state.setCombo);
  const time = useGameStore(state => state.time);
  const setTime = useGameStore(state => state.setTime);

  useEffect(() => {
    combo > 0 && setTime(100);
  }, [combo, setTime]);

  useEffect(() => {
    time <= 0 && setCombo(0);
  }, [time, setCombo]);

  useInterval(() => {
    time > 0 && setTime(time - 10);
  }, 1000);

  return (
    <Box justify="center" align="center" gap="xsmall" direction="row">
      <Text>Combo</Text>
      <Stack anchor="center">
        <Meter
          type="circle"
          size="xsmall"
          thickness="small"
          values={[{ value: time }]}
          style={{ height: 60 }}
        />
        <Text>{combo}</Text>
      </Stack>
    </Box>
  );
};

export default ComboCounter;
