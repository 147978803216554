import React from 'react';
import { Heading, Text, Image } from 'grommet';
import StyledView from './StyledView';
import clap from '../assets/clap.svg';

export default () => (
  <StyledView gap="medium">
    <Heading textAlign="center" margin="none">
      Awesome!
    </Heading>
    <Image style={{ width: 300 }} src={clap} />
    <Text>While you await the results, grab a snack and mingle!</Text>
  </StyledView>
);
