import React, { useEffect, useState } from 'react';
import { Grommet } from 'grommet';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
  useLocation,
} from 'react-router-dom';
import { useTransition, animated } from 'react-spring';
import 'sanitize.css';
import Game from './views/Game';
import theme from './theme';
import ErrorBoundary from './components/ErrorBoundary';
import Status404 from './views/Status404';
import ErrorView from './views/Error';
import Entries from './views/Entries';
import Terminal from './views/Terminal';
import Loading from './components/Loading';
import Awesome from './views/Awesome';
import { useUserStore } from './stores/user';
import { useSessionStore } from './stores/session';
import { useGameStore } from './stores/game';
import paths from './paths';
import './index.css';

const App = () => {
  const user = useUserStore(state => state.user);
  const signIn = useUserStore(state => state.signIn);
  const session = useSessionStore(state => state.id);
  const players = useSessionStore(state => state.players);
  const hasEnded = useSessionStore(state => state.hasEnded);
  const submitCode = useGameStore(state => state.submitCode);
  const hasJoined = user && players.includes(user.uid);

  useEffect(() => {
    signIn();
  }, [signIn]);

  useEffect(() => {
    if (hasEnded && hasJoined) {
      submitCode();
    }
  }, [hasEnded, hasJoined, submitCode]);

  return (
    <Grommet theme={theme} full>
      <ErrorBoundary fallback={({ error }) => <ErrorView error={error} />}>
        <Router>
          <Loading condition={user && session}>
            <Switch>
              <Routes />
            </Switch>
            {/*             {hasSubmitted || hasEnded ? (
              <Redirect to={paths.awesome} />
            ) : (
              hasJoined && <Redirect to={paths.game} />
            )} */}
          </Loading>
        </Router>
      </ErrorBoundary>
    </Grommet>
  );
};

const Routes = () => {
  const location = useLocation();
  const { pathname } = location;
  const user = useUserStore(state => state.user);
  const hasSubmitted = useGameStore(state => state.hasSubmitted);
  const players = useSessionStore(state => state.players);
  const hasEnded = useSessionStore(state => state.hasEnded);
  const id = useSessionStore(state => state.id);
  const hasJoined = user && players.includes(user.uid);
  const [redirect, setRedirect] = useState(null);

  // Haha wow! Damn ugly.
  useEffect(() => {
    if (pathname.includes(paths.entries) && id) {
      setRedirect(paths.entries);
    } else {
      if (hasJoined) {
        setRedirect(paths.game);
        if (hasSubmitted || hasEnded) {
          setRedirect(paths.awesome);
        }
      } else {
        if (!hasEnded) {
          setRedirect(paths.root);
        } else {
          setRedirect('/splash');
        }
      }
    }
  }, [pathname, hasJoined, hasSubmitted, hasEnded, id]);

  const transitions = useTransition(location, location => location.pathname, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  });
  return transitions.map(({ item: location, props, key }) => (
    <animated.div key={key} style={{ ...props, height: '100%' }}>
      <Switch location={location}>
        <Route exact path="/splash" component={Loading} />
        <Route exact path={paths.root} component={Terminal} />
        <Route path={paths.game} component={Game} />
        <Route path={paths.entries} component={Entries} />
        <Route path={paths.awesome} component={Awesome} />
        <Route component={Status404} />
      </Switch>
      {redirect && <Redirect to={redirect} />}
    </animated.div>
  ));
};

export default App;
