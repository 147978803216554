import create from 'zustand';
import fb, { onAuthChanged } from '../firebase';

const entriesStore = set => {
  onAuthChanged(() => {
    const ref = fb.database().ref('entries');

    ref.once('value').then(snapshot => {
      if (snapshot.exists()) {
        set({ ...snapshot.val() });
      }
    });

    ref.on('child_changed', snapshot => {
      if (snapshot.exists()) {
        const uid = snapshot.ref.key;
        set(state => ({
          ...state,
          [uid]: { ...state[uid], ...snapshot.val() },
        }));
      }
    });
  });

  return {};
};

export const [useEntriesStore] = create(entriesStore);
