import create from 'zustand';

const settingsStore = set => ({
  fontSize: 12,
  theme: 'dark',
  layout: 'row',
  setFontSize: fontSize => set({ fontSize }),
  setTheme: theme => set({ theme }),
  setLayout: layout => set({ layout }),
});

export const [useSettingsStore] = create(settingsStore);
