import React, { useState, useCallback, useEffect } from 'react';
import { Clock, Box } from 'grommet';
import { InProgress } from 'grommet-icons';
import { useSessionStore, sessionAPI } from '../stores/session';
import style from './style.module.css';

const finalCountdown = 'PT0H0M0S';

const CountDown = () => {
  const hasStarted = useSessionStore(state => state.hasStarted);

  const [time, setTime] = useState(finalCountdown);
  const [remaining, setRemaining] = useState(0);

  const isLastMinute = remaining <= 60 && remaining > 0;

  const getTimeStamp = useCallback(() => {
    const { endDate } = sessionAPI.getState();
    const remaining = Math.max(
      Math.ceil((new Date(endDate).getTime() - Date.now()) / 1000),
      0
    );

    // Does this belong here?
    setRemaining(remaining);

    const hours = Math.floor((remaining % (60 * 60 * 24)) / (60 * 60));
    const minutes = Math.floor((remaining % (60 * 60)) / 60);
    const seconds = Math.floor(remaining % 60);

    return `PT${hours}H${minutes}M${seconds}S`;
  }, []);

  useEffect(() => {
    if (!hasStarted) {
      setTime(finalCountdown);
    }
  }, [hasStarted]);

  return (
    <Box
      direction="row"
      gap="small"
      className={hasStarted && isLastMinute && style.countdown}
    >
      <InProgress color="accent-1" />
      <Clock
        type="digital"
        run={hasStarted}
        time={time}
        onChange={() => setTime(getTimeStamp())}
      />
    </Box>
  );
};

export default CountDown;
