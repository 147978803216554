import create from 'zustand';
import fb, { onAuthChanged } from '../firebase';
import { userAPI } from './user';
import { gameAPI } from './game';

const sessionStore = set => {
  onAuthChanged(() => {
    fb.database()
      .ref('session')
      .on('value', snapshot => {
        if (snapshot.exists()) {
          const {
            id,
            endDate,
            hasStarted,
            hasEnded,
            players = {},
          } = snapshot.val();
          set({
            id,
            endDate,
            hasStarted,
            hasEnded,
            players: Object.keys(players),
          });
        } else {
          set({ id: null });
        }
      });

    fb.database()
      .ref('users')
      .on('value', snapshot => {
        if (snapshot.exists()) {
          set({ users: snapshot.val() });
        }
      });
  });

  return {
    id: null,
    endDate: '',
    hasStarted: false,
    hasEnded: false,
    players: [],
    users: {},
    join: async () => {
      const {
        user: { uid },
      } = userAPI.getState();

      const updates = {
        [`session/players/${uid}`]: true,
        [`entries/${uid}`]: {
          html: '',
          css: '',
          javascript: '',
        },
      };

      await fb
        .database()
        .ref()
        .update(updates)
        .then(() => {
          gameAPI.setState({ hasSubmitted: false });
        });
    },
    leave: async () => {
      const {
        user: { uid },
      } = userAPI.getState();

      const updates = {
        [`session/players/${uid}`]: null,
        [`entries/${uid}`]: null,
      };

      await fb
        .database()
        .ref()
        .update(updates)
        .then(() => {
          gameAPI.setState({ hasSubmitted: false });
        });
    },
  };
};

export const [useSessionStore, sessionAPI] = create(sessionStore);
