export const colors = {
  primary: {
    leetGreen: '#80C565',
    terminalBlue: '#002B55',
    serverRack: '#26282A',
  },
  secondary: {
    northernLight: '#D0E6DA',
    nordicSnow: '#E9F6F6',
    lightOak: '#FEF6E8',
    katanaGray: '#4F565F',
  },
  blackout: '#040707',
};

export default {
  global: {
    font: {
      family: 'Montserrat',
    },
    colors: {
      brand: colors.primary.terminalBlue,
      'accent-1': colors.primary.leetGreen,
      'accent-2': colors.primary.serverRack,
      focus: colors.primary.leetGreen,
      blackout: colors.blackout,
    },
  },
};
