import React, {
  useState,
  useMemo,
  useRef,
  useEffect,
  useCallback,
} from 'react';
import { Box } from 'grommet';
import { Terminal as TerminalIcon } from 'grommet-icons';
import Console from 'react-console-emulator';
import { colors } from '../theme';
import rules from '../rules';
import style from './style.module.css';
import { useUserStore, userAPI } from '../stores/user';
import { sessionAPI } from '../stores/session';
import StyledView from './StyledView';

const styles = {
  style: {
    width: '100%',
    height: '100%',
    background: colors.primary.terminalBlue,
    borderRadius: '10px 10px 0 0',
  },
  contentStyle: { color: colors.secondary.northernLight },
  promptLabelStyle: {
    color: colors.primary.leetGreen,
    flex: '1 0 auto',
    fontWeight: 800,
    fontStyle: 'italic',
  },
  inputAreaStyle: { alignItems: 'baseline', color: colors.primary.leetGreen },
  inputStyle: {
    color: colors.secondary.northernLight,
    padding: '0 0.5em 0 0',
    width: 'auto',
  },
};

const ConsoleSpan = props => <span className={style.consoleSpan} {...props} />;

const welcomeMessage = [
  'Welcome to Code: Blackout!',
  'type `help` to see available commands.',
];

const errorText =
  "Command '[command]' not found! Type 'help' to see available commands.";

const Terminal = () => {
  const username = useUserStore(state => state.user.displayName);
  const [disabled, setDisabled] = useState(false);
  const ref = useRef();

  const writeToConsole = useCallback(
    text => ref.current && ref.current.pushToStdout(text),
    [ref]
  );

  const asyncCommand = useCallback(
    (fn, cb) => {
      setDisabled(true);
      fn()
        .then(cb)
        .catch(({ message }) => writeToConsole(message))
        .finally(() => setDisabled(false));
    },
    [setDisabled, writeToConsole]
  );

  // Toggle input disabled
  useEffect(() => {
    const terminal = ref.current;
    if (terminal) {
      const input = terminal.terminalInput.current;
      if (disabled) {
        input.setAttribute('disabled', true);
      } else {
        input.removeAttribute('disabled');
        terminal.focusTerminal();
      }
    }
  }, [disabled]);

  const commands = useMemo(() => {
    return {
      whoami: {
        description: 'Show username.',
        fn: () => {
          const {
            user: { displayName, uid },
          } = userAPI.getState();

          return Array.from(new Set([uid, displayName])).join(' - ');
        },
      },
      name: {
        description: 'Set username',
        usage: 'name <string>',
        fn: name => {
          if (!name) {
            return 'Missing name (see "help")';
          }
          const { setName } = userAPI.getState();
          asyncCommand(
            () => setName(name),
            () => writeToConsole('Name successfully set!')
          );

          return 'Setting name...';
        },
      },
      session: {
        description: 'Show available session.',
        fn: () => {
          const { id, players, hasStarted, users } = sessionAPI.getState();
          return (
            <>
              <ConsoleSpan>Session: {id}</ConsoleSpan>
              <ConsoleSpan>Active: {String(hasStarted)}</ConsoleSpan>
              <ConsoleSpan>Players:</ConsoleSpan>
              {players.map(player => (
                <ConsoleSpan key={player}>* {users[player]}</ConsoleSpan>
              ))}
            </>
          );
        },
      },
      join: {
        description: 'Join active session.',
        fn: () => {
          const { join } = sessionAPI.getState();
          asyncCommand(join, () => writeToConsole('Joined session!'));

          return 'Joining session...';
        },
      },
      leave: {
        description: 'Leave active session.',
        fn: () => {
          const { leave } = sessionAPI.getState();
          asyncCommand(leave, () => writeToConsole('Left session!'));

          return 'Leaving session...';
        },
      },
      rules: {
        description: 'Show rules.',
        fn: () =>
          Object.entries(rules).map(([emoji, rule]) => (
            <ConsoleSpan key={rule}>
              {emoji} - {rule}
            </ConsoleSpan>
          )),
      },
    };
  }, [asyncCommand, writeToConsole]);

  return (
    <StyledView>
      <Console
        ref={ref}
        {...styles}
        contentClassName={style.terminalContent}
        inputAreaClassName={style.terminalInputArea}
        autoFocus
        commandCallback={command => console.log(command)}
        commands={commands}
        welcomeMessage={welcomeMessage}
        promptLabel={`${username}@blackout:`}
        errorText={errorText}
      />
      <Box
        background="blackout"
        fill="horizontal"
        pad="small"
        style={{ borderRadius: '0 0 10px 10px' }}
      >
        <TerminalIcon />
      </Box>
    </StyledView>
  );
};

export default Terminal;
