import React from 'react';
import styled from 'styled-components';
import { Box } from 'grommet';
import theme from '../theme';

const {
  global: { colors },
} = theme;

const WithStyle = styled(Box)`
  background: radial-gradient(circle, ${colors.brand}, ${colors.blackout});
`;

export default props => (
  <WithStyle
    fill
    background="brand"
    justify="center"
    align="center"
    pad="large"
    {...props}
  />
);
