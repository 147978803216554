import React from 'react';
import { Grid, Box } from 'grommet';
import Header from '../components/Header';
import CodeEditor from '../components/CodeEditor';
import Footer from '../components/Footer';
import Loading from '../components/Loading';
import { useSettingsStore } from '../stores/settings';
import { useGameStore } from '../stores/game';

const Game = () => {
  const layout = useSettingsStore(state => state.layout);
  const hasSynced = useGameStore(state => state.hasSynced);
  return (
    <Grid fill rows={['xsmall', 'auto', 'xsmall']} columns={['auto']}>
      <Header background="brand" />
      <Box fill direction={layout} gap="2px" background="accent-2">
        <Loading condition={hasSynced}>
          {['html', 'css', 'javascript'].map(mode => (
            <CodeEditor key={mode} mode={mode} background="brand" />
          ))}
        </Loading>
      </Box>
      <Footer background="brand" />
    </Grid>
  );
};

export default Game;
