import React, { useState } from 'react';
import { Box, Image, Button } from 'grommet';
import { Performance } from 'grommet-icons';
import Settings from './Settings';
import Modal from './Modal';
import CountDown from './CountDown';
import ComboCounter from './ComboCounter';
import logo from '../assets/tretton37.png';

const Header = props => {
  const [showSettings, setShowSettings] = useState(false);
  return (
    <>
      <Box
        as="header"
        {...props}
        direction="row"
        justify="between"
        align="center"
        gap="medium"
        pad="medium"
      >
        <Box height="xsmall" width="xsmall">
          <Image fit="contain" src={logo} />
        </Box>
        <CountDown />
        <ComboCounter />
        <Button icon={<Performance />} onClick={() => setShowSettings(true)} />
      </Box>
      <Modal
        position="right"
        full="vertical"
        title="Editor settings"
        isOpen={showSettings}
        onClose={() => setShowSettings(false)}
      >
        <Settings />
      </Modal>
    </>
  );
};

export default Header;
